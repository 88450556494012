 <template>
  <div class="w-full p-2">
      <div>
        <AsociarUnidadesHeader :titulo="titulo" /> 
      </div>  
      <div>
        <span class="text-lg font-bold pl-2">
          {{ sub_titulo }}           
        </span> 
      </div>  
      <div>
        <AsociarUnidadesComp :send_datos="send_datos" @guardar_asociar_unidad="return_asociar_unidad"></AsociarUnidadesComp>
      </div>

  </div>
</template>

<script>
import AsociarUnidadesHeader from "@/components/comunes/HeaderModulo.vue";
import AsociarUnidadesComp from "@/components/configuracion/Unidades/AsociarUnidadesComp.vue";
export default {
  name: 'AsociarUnidadesOpciones',
  data () {
    return {
      datos_usuario: [],
      send_usuario: {},
    }
  },
  props: {
      send_datos: Object,
      titulo: String,
      sub_titulo: String
  },  
  setup() {
  },  
  computed: {
  },
  components: {
    AsociarUnidadesComp,
    AsociarUnidadesHeader
  //  ThemifyIcon    
  },  
  watch: {

  },  
  methods: {
    return_asociar_unidad (msg) {
        //this.buscarPersona('params')

      this.$emit("guardar_asociar_unidad", msg);

    },        

  },
  mounted () {
    //this.searchAsociarUnidadesComp({})
    /*  this.$store.dispatch(READ_USUARIO, {}).then(response => {

          this.datos_usuario = response.rest.items
      }, error => {
          this.$store.commit(SET_ERROR, error.statusText)
      })      
*/

  },

}
</script>
