 <template>
  <div class="w-full p-2">
      <div>
        <UnidadesHeader :titulo="titulo" /> 
      </div>  
      <div>
        <span class="text-lg font-bold pl-2">
          {{ sub_titulo }}           
        </span> 
      </div>  
      <div>
        <UnidadesComp :send_datos="send_datos" @guardar_definir_unidad="return_definir_unidad"></UnidadesComp>
      </div>

  </div>
</template>

<script>
import UnidadesHeader from "@/components/comunes/HeaderModulo.vue";
import UnidadesComp from "@/components/configuracion/Unidades/UnidadesComp.vue";
export default {
  name: 'UnidadesOpciones',
  data () {
    return {
      datos_usuario: [],
      send_usuario: {},
    }
  },
  props: {
      send_datos: Object,
      titulo: String,
      sub_titulo: String
  },  
  setup() {
  },  
  computed: {
  },
  components: {
    UnidadesComp,
    UnidadesHeader
  //  ThemifyIcon    
  },  
  watch: {

  },  
  methods: {
    return_definir_unidad (msg) {
        //this.buscarPersona('params')

      this.$emit("guardar_definir_unidad", msg);

    },        

  },
  mounted () {
    //this.searchUnidadesComp({})
    /*  this.$store.dispatch(READ_USUARIO, {}).then(response => {

          this.datos_usuario = response.rest.items
      }, error => {
          this.$store.commit(SET_ERROR, error.statusText)
      })      
*/

  },

}
</script>
