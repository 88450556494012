 <template>
  <div class="w-full p-2">
      <div>
        <AsociarUnidadesOpciones :titulo="titulo" :sub_titulo="sub_titulo" :send_datos="send_datos" @guardar_asociar_unidad="return_asociar_unidad"></AsociarUnidadesOpciones>
      </div>

  </div>
</template>

<script>
import AsociarUnidadesOpciones from "@/views/configuracion/Academica/Unidades/AsociarUnidadesOpciones.vue";


export default {
  name: 'Unidades',
  data () {
    return {
      datos_academia: [],
    }
  },
  setup() {
  },  
  computed: {
  },
  props: {
      send_datos: Object,
      titulo: String,
      sub_titulo: String
  },  
  components: {
    AsociarUnidadesOpciones
  //  ThemifyIcon    
  },  
  watch: {

  },  
  methods: {
    return_asociar_unidad (msg) {
        //this.buscarPersona('params')

      this.$emit("guardar_asociar_unidad", msg);

    },        

  },
  mounted () {

  },

}
</script>
