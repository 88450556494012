var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[(_vm.mensaje.satisfactorio.status || _vm.mensaje.nosatisfactorio.status )?_c('div',{staticClass:"sticky top-0 z-50"},[_c('NotificacionInterna',{attrs:{"mensaje":_vm.mensaje}})],1):_vm._e(),_c('div',{},[_c('div',[_c('v-data-table',{staticClass:"elevation-1 mb-10",attrs:{"headers":_vm.headers,"items":_vm.fields_academico.group_unidad.json_group_unidad.json_group_unidad,"sort-by":"nu_id_institucion","loading":_vm.isLoading,"loading-text":"Cargando... Por favor espere"},scopedSlots:_vm._u([{key:"item.json_unidades",fn:function(ref){
var item = ref.item;
return _vm._l((item.json_unidades),function(item_unidad,index){return _c('div',{key:index},[_vm._v(" "+_vm._s(item_unidad.tx_denominacion_unidad)+" ")])})}},{key:"item.json_lapso",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.json_lapso.tx_nombre_lapso)+" ")]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" Asociar unidad ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('ValidationObserver',{ref:"datos_basicos_plan_institucion"},[_c('div',[_c('validation-provider',{attrs:{"name":"fields_academico.group_unidad.json_lapso.json_lapso","rules":_vm.fields_academico.group_unidad.json_lapso.caracteristicas.required},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"input-group--focused text-lg",attrs:{"items":_vm.list_lapsos,"item-value":"nu_id_lapso","item-text":"tx_nombre_lapso","placeholder":_vm.fields_academico.group_unidad.json_lapso.caracteristicas.placeholder,"error-messages":errors,"label":_vm.fields_academico.group_unidad.json_lapso.caracteristicas.label,"outlined":"","return-object":"","disabled":!_vm.fields_academico.isEditing},model:{value:(_vm.fields_academico.group_unidad.json_lapso.json_lapso),callback:function ($$v) {_vm.$set(_vm.fields_academico.group_unidad.json_lapso, "json_lapso", $$v)},expression:"fields_academico.group_unidad.json_lapso.json_lapso"}})]}}])})],1),_c('div',[_c('validation-provider',{attrs:{"name":"fields_academico.group_unidad.json_unidades.json_unidades","rules":_vm.fields_academico.group_unidad.json_unidades.caracteristicas.required},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.list_unidades,"error-messages":errors,"chips":"","outlined":"","color":"blue-grey lighten-2","label":_vm.fields_academico.group_unidad.json_unidades.caracteristicas.label,"item-value":"nu_id_unidad","item-text":"tx_denominacion_unidad","multiple":"","return-object":"","disabled":!_vm.fields_academico.isEditing},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('v-chip',_vm._b({attrs:{"input-value":data.selected,"close":""},on:{"click":data.select,"click:close":function($event){return _vm.remove(data.item)}}},'v-chip',data.attrs,false),[_vm._v(" "+_vm._s(data.item.tx_denominacion_unidad)+" ")])]}}],null,true),model:{value:(_vm.fields_academico.group_unidad.json_unidades.json_unidades),callback:function ($$v) {_vm.$set(_vm.fields_academico.group_unidad.json_unidades, "json_unidades", $$v)},expression:"fields_academico.group_unidad.json_unidades.json_unidades"}})]}}])})],1)])],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.close}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.save}},[_vm._v(" Guardar ")])],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v("Esta seguro de borrar este registro?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeDelete}},[_vm._v("No")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.deleteItemConfirm}},[_vm._v("Si")]),_c('v-spacer')],1)],1)],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiPencil)+" ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiDelete)+" ")])]}},{key:"no-data",fn:function(){return [_c('span',[_vm._v(" No hay información ")])]},proxy:true}])})],1)]),_c('div',{staticClass:"flex justify-end"},[_c('div',[_c('button',{staticClass:"hidden md:flex w-full  font-semibold bg-skin-button-muted  text-white rounded-lg hover:bg-skin-button-accent-hover cursor-pointer py-2 px-6 mr-1",on:{"click":function($event){return _vm.cancelar()}}},[_vm._v(" Cerrar ")]),_c('button',{staticClass:"flex md:hidden w-full  font-semibold bg-skin-button-muted  text-white rounded-lg hover:bg-skin-button-accent-hover cursor-pointer py-2 px-6 mr-1",on:{"click":function($event){return _vm.cancelar()}}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" "+_vm._s(_vm.icons.mdiStopCircleOutline)+" ")])],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }