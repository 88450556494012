<template>
  <div class="">
        <div  class="sticky top-0 z-50" v-if="mensaje.satisfactorio.status || mensaje.nosatisfactorio.status ">
          <NotificacionInterna :mensaje="mensaje" />  
        </div>

      <div>
        <p class="text-sm pl-2">
        <v-textarea
          v-model="tx_contenido"
          outlined
          class="input-group--focused text-lg pt-2"
          label='Contenido'
          filled
          name="input-7-4"
          disabled                            
          value="The Woodman set to work at once, and so sharp was his axe that the tree was soon chopped nearly through."
        ></v-textarea>

        </p> 
      </div>  
        <div class="">
          <div>              
              <v-data-table
                :headers="headers"
                :items="fields_academico.group_unidad.json_group_unidad.json_group_unidad"
                sort-by="nu_id_institucion"
                class="elevation-1 mb-10"
                :loading="isLoading"
                loading-text="Cargando... Por favor espere"                    
              >
              <!--
                <template v-slot:item.json_institucion="{ item }">
                    {{ item.json_institucion.tx_razon_social }}
                </template>     
                <template v-slot:item.json_plan_estudio="{ item }">
                    {{ item.json_plan_estudio.co_plan_estudio }}
                </template>     
              -->

                <template v-slot:top>
                  <v-toolbar
                    flat
                  >
                          <v-spacer></v-spacer>
                          <v-dialog
                            v-model="dialog"
                            max-width="500px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                color="primary"
                                dark
                                class="mb-2"
                                v-bind="attrs"
                                v-on="on"
                              >
                                Definir unidad                                  
                              </v-btn>

                            </template>
                            <v-card>
                              <v-card-title>
                                <span class="text-h5">{{ formTitle }}</span>
                              </v-card-title>
                              <v-card-text>
                                <ValidationObserver ref="datos_basicos_plan_institucion">

                                      <div>
                                          <v-tooltip right>
                                            <template v-slot:activator="{ on, attrs }">

                                          <validation-provider
                                            v-slot="{ errors }"
                                            name="fields_academico.group_unidad.co_unidad.co_unidad"
                                            :rules=fields_academico.group_unidad.co_unidad.caracteristicas.required
                                          >
                                            <v-text-field
                                              v-model="fields_academico.group_unidad.co_unidad.co_unidad"
                                              outlined
                                              class="input-group--focused text-lg pt-2"
                                              :error-messages="errors"
                                              :label=fields_academico.group_unidad.co_unidad.caracteristicas.label
                                              v-bind="attrs"
                                              v-on="on"
                                              :disabled=!fields_academico.isEditing                            
                                            ></v-text-field>
                                          </validation-provider>     
                                            </template>
                                            <span>{{fields_academico.group_unidad.co_unidad.caracteristicas.tooltip  }} </span>
                                          </v-tooltip>                
                                      </div>
                                      <div>
                                          <v-tooltip right>
                                            <template v-slot:activator="{ on, attrs }">

                                          <validation-provider
                                            v-slot="{ errors }"
                                            name="fields_academico.group_unidad.tx_denominacion_unidad.tx_denominacion_unidad"
                                            :rules=fields_academico.group_unidad.tx_denominacion_unidad.caracteristicas.required
                                          >
                                            <v-textarea
                                              v-model="fields_academico.group_unidad.tx_denominacion_unidad.tx_denominacion_unidad"
                                              outlined
                                              class="input-group--focused text-lg pt-2"
                                              :error-messages="errors"
                                              :label=fields_academico.group_unidad.tx_denominacion_unidad.caracteristicas.label
                                              v-bind="attrs"
                                              v-on="on"
                                              :disabled=!fields_academico.isEditing                            
                                            ></v-textarea>
                                          </validation-provider>     
                                            </template>
                                            <span>{{fields_academico.group_unidad.tx_denominacion_unidad.caracteristicas.tooltip  }} </span>
                                          </v-tooltip>                
                                      </div>
                                </ValidationObserver>
                              </v-card-text>

                              <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                  color="blue darken-1"
                                  text
                                  @click="close"
                                >
                                  Cancelar
                                </v-btn>
                                <v-btn
                                  color="blue darken-1"
                                  text
                                  @click="save"
                                >
                                  Guardar
                                </v-btn>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                          <v-dialog v-model="dialogDelete" max-width="500px">
                            <v-card>
                              <v-card-title class="text-h5">Esta seguro de borrar este registro?</v-card-title>
                              <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="blue darken-1" text @click="closeDelete">No</v-btn>
                                <v-btn color="blue darken-1" text @click="deleteItemConfirm">Si</v-btn>
                                <v-spacer></v-spacer>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                  </v-toolbar>
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-icon
                    small
                    class="mr-2"
                    @click="editItem(item)"
                  >
                    {{ icons.mdiPencil }}                                            
                  </v-icon>
                  <v-icon
                    small
                    @click="deleteItem(item)"
                  >
                    {{ icons.mdiDelete }}                                            
                    
                  </v-icon>
                </template>
                <template v-slot:no-data>
                  <span>
                    No hay información
                  </span>
                </template>
            </v-data-table>
          </div>

        </div>                  


                <!-- Inicio Boton de Navegacion -->
            <div class="flex justify-end">

                <div>
                        <button @click="cancelar()" class="hidden md:flex w-full  font-semibold bg-skin-button-muted  text-white rounded-lg hover:bg-skin-button-accent-hover cursor-pointer py-2 px-6 mr-1">
                                    Cerrar
                        </button>                          
                        <button @click="cancelar()" class="flex md:hidden w-full  font-semibold bg-skin-button-muted  text-white rounded-lg hover:bg-skin-button-accent-hover cursor-pointer py-2 px-6 mr-1">
                                        <v-icon
                                        color="white"
                                        >                                
                                        {{ icons.mdiStopCircleOutline }}
                                        </v-icon>
                        </button>                          
                    
                </div>
            </div>
            <!-- Fin Boton de Navegacion -->    

       
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { LIST_CONTENIDO, CREATE_UNIDAD } from '@/store/actions.type'
import { SET_ERROR } from '@/store/mutations.type'
import NotificacionInterna from "@/components/comunes/NotificacionInterna";

import "@/common/rules.js";
import { reactive  } from "@vue/composition-api"
import {
  mdiClose,
  mdiAccount,
  mdiAlarmMultiple,
  mdiAlarmPlus,
  mdiPencil,
  mdiDelete,
  mdiCalendar,
  mdiAccountHeart,
  mdiNeedle,
  mdiRadiologyBoxOutline,
  mdiCached
} from '@mdi/js'


export default {
  name: 'Unidad',
  data () {
    return {
      tx_contenido: '',
        mensaje: {
          satisfactorio: {
            status: false,
            texto: ''
          },
          nosatisfactorio: {
            status: false,
            texto: ''
          },
        },
        isLoading: false,
        dialog: false,
        dialogDelete: false,
        editedIndex: -1,
        editedItem: {
          json_institucion: '',
          json_especialidad: '',
        },  
        headers: [
            {
              text: 'Código de unidad',
              align: 'start',
              sortable: true,
              value: 'co_unidad',
            },
            { text: 'Denominación de la unidad', value: 'tx_denominacion_unidad' },                    
            { text: 'Actions', value: 'actions', sortable: false },
        ],
    }
  },
  setup() {
    const icons = reactive({
        mdiClose,
        mdiAccount,
        mdiAlarmMultiple,
        mdiAlarmPlus,
        mdiPencil,
        mdiCalendar,
        mdiAccountHeart,
        mdiNeedle,
        mdiRadiologyBoxOutline,
        mdiCached,
        mdiDelete,
      });  


    return {
      icons
    }
  },  
  watch: {
  },     
  computed: {
    ...mapGetters(['fields_academico']),
    formTitle () {
      return this.editedIndex === -1 ? 'Nuevo Item' : 'Editar Item'
    },
  },
  props: {
    send_datos: Object,
    titulo: String
  },
  components: {
    NotificacionInterna
  },    
  methods: {
    searchContenido(params) {
          this.$store.dispatch(LIST_CONTENIDO, params).then(response => {
              response.rest.items.forEach(element => {
                console.log('Parametros año escolar: ', element.tx_contenido)
                this.tx_contenido = element.tx_contenido
              });
          }, error => {
              this.$store.commit(SET_ERROR, error.statusText)
          })        
    },    
      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.cleanig()
          //this.fields_academico.group_unidad.json_group_unidad.json_group_unidad = []
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          //this.institucion_especialidad = Object.assign({}, this.fields_academico.group_unidad.json_institucion.json_institucion)
          this.editedIndex = -1
        })
      },
      save () {
        if (this.editedIndex > -1) {
          Object.assign(this.items_niveles[this.editedIndex], this.institucion_especialidad)
        } else {
          //console.log('this.editedItem: ', this.fields_academico.group_unidad.tx_nombre_ano.tx_nombre_ano)
          /*var aa = {
            json_institucion: this.institucion_especialidad.json_institucion,
            json_especialidad: this.institucion_especialidad.json_especialidad
          }
          */
          //this.fields_academico.json_institucion.json_especialidad = this.institucion_especialidad.json_especialidad
          this.fields_academico.group_unidad.json_group_unidad.json_group_unidad.push({
            nu_id_ano_escolar: this.send_datos.datos_academicos.nu_id_ano_escolar,
            nu_id_institucion: this.send_datos.datos_academicos.nu_id_institucion,
            nu_id_nivel_educativo: this.send_datos.datos_academicos.nu_id_nivel_educativo,
            nu_id_grado: this.send_datos.datos_academicos.nu_id_grado,
            nu_id_plan: this.send_datos.datos_academicos.nu_id_plan,
            nu_id_area_formacion: this.send_datos.datos_academicos.nu_id_area_formacion,
            co_unidad: this.fields_academico.group_unidad.co_unidad.co_unidad,
            tx_denominacion_unidad: this.fields_academico.group_unidad.tx_denominacion_unidad.tx_denominacion_unidad,
          })


            //this.buscarPersona('params')

            const formData = new FormData()
            formData.append('unidad', JSON.stringify(this.fields_academico.group_unidad.json_group_unidad.json_group_unidad))

        // desde aca optimizar
            this.mensaje.satisfactorio.status = false
            this.mensaje.nosatisfactorio.status = false

            //formData = JSON.parse(JSON.stringify(guardar.formData))

            formData.append('oper', 'REGISTRO')

            this.$store.dispatch(CREATE_UNIDAD, formData).then(datos => { 
                  this.mensaje.satisfactorio.texto = "Registro satisfactorio"
                  this.mensaje.satisfactorio.status = true
                  
                  setTimeout(() => {
                        this.isLoading = false
                    //    this.$emit("guardar_usuario", JSON.stringify(this.fields_academico));
                        console.log('message emit from child component01: ', datos)
                        this.mensaje.satisfactorio.status = false
                        this.$notify({
                          group: 'generic',
                          type: 'success',
                          title: 'Mensaje importante',
                          classes: 'w-full text-lg',
                          text: 'Datos  creado satisfactoriamente!'
                        });      

                    },3000)                  
                    
                    this.$emit("actualizar_datos_academico", this.fields_academico.group_unidad.json_group_unidad.json_group_unidad);              
                    
                    //this.$refs.tabs_ubigeo_tabJustify.click();

                  
              }, error => {
                  this.$store.commit(SET_ERROR, error.statusText)
                  this.mensaje.nosatisfactorio.texto = "Registro de prototipo cancelado"
                  this.mensaje.nosatisfactorio.status = true
                  this.ocultar_pestana()
                  this.mostrar_pestana('collapseMotivo')
                  setTimeout(() => {
                      this.mensaje.nosatisfactorio.status = false
                      },3000);             

              })  

          /// hasta aca optimizar


          //this.fields_academico.group_unidad.json_group_unidad.json_group_unidad = this.items_data_table
          console.log('this.editedItem items_data_table: ', this.fields_academico.group_unidad.json_group_unidad.json_group_unidad)
          //this.fields_academico.group_unidad.json_institucion.json_institucion.push(this.institucion_especialidad.json_institucion)
        }
        this.close()
      },    
      initialize () {
        this.institucion_especialidad = this.defaultItem
      },

      editItem (item) {
        //this.searchInstitucion(item.json_institucion.nu_id_institucion)
        this.editedIndex = this.fields_academico.group_unidad.json_group_unidad.json_group_unidad.indexOf(item)
        const claves_primarias = {}
        claves_primarias.tx_nombre_ano = 'tx_nombre_ano'
        for (const property in item) {
            //console.log('item: ', property)
            if (claves_primarias[property] != property) {
              this.fields_academico.group_unidad[property][property] = item[property]
            }               
        }

        this.dialog = true
      },
      cleanig () {
        const claves_primarias = {}
        claves_primarias.json_group_unidad = 'json_group_unidad'

        for (const property in this.fields_academico.group_unidad) {
            if (claves_primarias[property] != property) {
              const myArray = property.split("_"); 
              if (myArray[0] === 'JSON') {
                this.fields_academico.group_unidad[property][property] = []
              } else {
                this.fields_academico.group_unidad[property][property] = ''
              }          
            }          
        }

      },
      deleteItem (item) {
        console.log('deleteItem: ', item)
        this.editedIndex = this.fields_academico.group_unidad.json_group_unidad.json_group_unidad.indexOf(item)
        console.log('editedIndex: ', this.editedIndex)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },
      deleteItemConfirm () {
        this.fields_academico.group_unidad.json_group_unidad.json_group_unidad.splice(this.editedIndex, 1)
        this.closeDelete()
      },
      
    async guardar () {
          console.log('Guardar: ')
          var isValid = false
          if (this.fields_academico.group_unidad.json_group_unidad.json_group_unidad) {
            isValid = true
          }
          if (isValid) {
            this.$emit("guardar_definir_unidad", JSON.stringify(this.fields_academico.group_unidad.json_group_unidad.json_group_unidad));
          }          


        // const isValid = await this.$refs.datos_basicos_plan_institucion.validate()
        // if (!isValid) {
        //     return true
        // }
    },              
    async cancelar () {
    //this.isloading4 = true 
        this.$emit("guardar_definir_unidad", JSON.stringify(this.fields_academico));

    //this.fields_historia_clinica.isEditing = !this.fields_historia_clinica.isEditing     

    }, 
          


  },
  
  mounted() {
    console.log('searchContenido: ' )
    this.searchContenido({
            nu_id_ano_escolar: this.send_datos.datos_academicos.nu_id_ano_escolar,
            nu_id_institucion: this.send_datos.datos_academicos.nu_id_institucion,
            nu_id_nivel_educativo: this.send_datos.datos_academicos.nu_id_nivel_educativo,
            nu_id_plan: this.send_datos.datos_academicos.nu_id_plan,
            nu_id_grado: this.send_datos.datos_academicos.nu_id_grado,
            nu_id_area_formacion: this.send_datos.datos_academicos.nu_id_area_formacion,

    });
/*     this.searchAnos({ })
    this.fields_academico.group_unidad.tx_nombre_ano.tx_nombre_ano = this.send_datos.AnoEscolar.tx_nombre_ano
    this.searchInstituciones()
    this.searchNiveles({
        nu_id_ano_escolar: this.send_datos.AnoEscolar.nu_id_ano_escolar, 
        nu_id_nivel_educativo: ''
        }
    )
    this.searchAnoInstitucion(this.send_datos.AnoEscolar.nu_id_ano_escolar) */
    //this.searchAreas('oficial')
    if (this.send_datos.operacion === 'EDITAR') {
        //this.fields_academico.group_unidad.json_institucion.json_institucion = JSON.parse(this.send_datos.usuario.json_institucion)
        //this.fields_academico.group_unidad.json_especialidad.json_especialidad = JSON.parse(this.send_datos.usuario.json_especialidad)
          // for (var prop in this.send_datos.usuario) {
          //     if(typeof this.fields_academico[prop] === 'object') {
          //           if (this.fields_academico[prop]['caracteristicas']['tipo'] === 'JSON') {
          //             this.fields_academico[prop][prop] = JSON.parse(this.send_datos.usuario[prop])
          //           } else {
          //             this.fields_academico[prop][prop] = this.send_datos.usuario[prop]
          //           }
          //     } else {
          //           this.fields_academico[prop][prop] = this.send_datos.usuario[prop]
          //     }
          // }
    } else {
        //this.limpiar()
        //this.list_especialidades = JSON.parse(this.fields_academico.group_unidad.json_institucion.json_institucion[0].json_especialidad)
        //this.fields_academico.group_unidad.json_group_unidad.json_group_unidad = []
        this.fields_academico.isEditing = true
    }           
  },
    
}
</script>